export default {
  incorrectUsrPwd: 'Некорректный логин или пароль',
  askDeleteUser: 'Вы действительно хотите удалить этого пользователя?',
  askDeleteTemp: 'Вы действительно хотите удалить этот шаблон?',
  askDeleteEnv: 'Вы действительно хотите удалить это окружение?',
  askDeleteInv: 'Вы действительно хотите удалить этот инвентарь?',
  askDeleteKey: 'Вы действительно хотите удалить этот ключ?',
  askDeleteRepo: 'Вы действительно хотите удалить этот репозиторий?',
  askDeleteProj: 'Вы действительно хотите удалить этот проект?',
  askDeleteTMem: 'Вы действительно хотите удалить этого участника команды?',
  edit: 'Изменить',
  nnew: 'Новый',
  keyFormSshKey: 'SSH ключ',
  keyFormLoginPassword: 'Логин с паролем',
  keyFormNone: 'Ничего',
  incorrectUrl: 'Некорректный URL',
  username: 'Имя пользователя',
  username_required: 'Имя пользователя обязательно',
  dashboard: 'Панель',
  history: 'История',
  activity: 'Активность',
  settings: 'Настройки',
  signIn: 'Войти',
  password: 'Пароль',
  changePassword: 'Изменить пароль',
  editUser: 'Изменить пользователя',
  newProject: 'Новый проект',
  close: 'Закрыть',
  newProject2: 'Новый проект...',
  demoMode: 'Демо режим',
  task: 'Задача #{expr}',
  youCanRunAnyTasks: 'Вы можете запускать любые задачи',
  youHaveReadonlyAccess: 'Вы имеете доступ "только для чтения"',
  taskTemplates: 'Шаблоны задач',
  inventory: 'Инвентарь',
  environment: 'Окружение',
  keyStore: 'Хранилище ключей',
  repositories: 'Репозитории',
  darkMode: 'Тёмная тема',
  team: 'Команда',
  users: 'Пользователи',
  editAccount: 'Изменить учётную запись',
  signOut: 'Выйти',
  error: 'Ошибка',
  refreshPage: 'Обновить страницу',
  relogin: 'Перезайти',
  howToFixSigninIssues: 'Как устранить проблемы со входом в систему',
  firstlyYouNeedAccessToTheServerWhereSemaphoreRunni: 'Во-первых, необходим доступ к серверу, на котором работает Semaphore.',
  executeTheFollowingCommandOnTheServerToSeeExisting: 'Выполните следующую команду на сервере, чтобы увидеть существующих пользователей:',
  semaphoreUserList: 'Список пользователей semaphore',
  youCanChangePasswordOfExistingUser: 'Вы можете изменить пароль существующего пользователя:',
  semaphoreUserChangebyloginLoginUser123Password: 'semaphore user change-by-login --login user123 --password {makePasswordExample}',
  orCreateNewAdminUser: 'Или создать нового пользователя с поными правами:',
  close2: 'Закрыть',
  semaphore: 'Семафор',
  dontHaveAccountOrCantSignIn: 'Нет учётной записи или не можете войти?',
  password2: 'Пароль',
  cancel: 'Закрыть',
  noViews: 'Нет видов',
  addView: 'Добавить вид',
  editEnvironment: 'Изменить окружение',
  deleteEnvironment: 'Удалить окружение',
  environment2: 'Окружение',
  newEnvironment: 'Новое окружение',
  environmentName: 'Имя окружения',
  extraVariables: 'Дополнительные переменные',
  enterExtraVariablesJson: 'Ввести дополнительные переменные в формате JSON...',
  environmentVariables: 'Переменные окружения',
  enterEnvJson: 'Введите переменную окружения в формате JSON...',
  environmentAndExtraVariablesMustBeValidJsonExample: 'Окружениеи дополнительные переменные должны быть корректным JSON. Например:',
  dashboard2: 'Панель',
  ansibleSemaphore: 'Ансибл Семафор',
  wereSorryButHtmlwebpackpluginoptionstitleDoesntWor: 'Извините, но <%= htmlWebpackPlugin.options.title %> не работает без включенного JavaScript. Пожалуйста включите, чтобы продолжить',
  deleteInventory: 'Удалить инвентарь',
  newInventory: 'Новый инвентарь',
  name: 'Имя',
  userCredentials: 'Учётные данные пользователя',
  sudoCredentialsOptional: 'Повышенные учётные данные (дополнительно)',
  type: 'Тип',
  pathToInventoryFile: 'Путь до файла инвентаря',
  enterInventory: 'Введите инвентарь...',
  staticInventoryExample: 'Пример статичного инвентаря:',
  staticYamlInventoryExample: 'Пример статичного инвентаря в YAML:',
  keyName: 'Имя ключа',
  loginOptional: 'Логин (дополнительно)',
  usernameOptional: 'Имя пользователя (дополнительно)',
  privateKey: 'Закрытый ключ',
  override: 'Переопределить',
  useThisTypeOfKeyForHttpsRepositoriesAndForPlaybook: 'Используйте этот тип ключа для HTTPS-репозиториев и для плейбуков, использующих не-SSH-соединения.',
  deleteKey: 'Удалить ключ',
  newKey: 'Новый ключ',
  create: 'Создать',
  newTask: 'Новая задача',
  cantDeleteThe: 'Невозможно удалить {objectTitle}',
  theCantBeDeletedBecauseItUsedByTheResourcesBelow: '{objectTitle} нельзя удалить, так как он используется следующими ресурсами',
  projectName: 'Имя проекта',
  allowAlertsForThisProject: 'Разрешить оповещения для этого проекта',
  telegramChatIdOptional: 'Идентификатор чата Телеграм (дополнительно)',
  maxNumberOfParallelTasksOptional: 'Максимальное число параллельных задач (дополнительно)',
  deleteRepository: 'Удалить репозиторий',
  newRepository: 'Новый репозиторий',
  urlOrPath: 'URL или путь',
  absPath: 'abs. path',
  branch: 'Ветка',
  accessKey: 'Ключ доступа',
  credentialsToAccessToTheGitRepositoryItShouldBe: 'Учетные данные для доступа к репозиториям Git. Должен быть:',
  ifYouUseGitOrSshUrl: 'если вы используете Git или SSH URL.',
  ifYouUseHttpsOrFileUrl: 'если вы используете HTTPS или file URL.',
  none: 'Ничего',
  ssh: 'SSH',
  deleteProject: 'Удалить проект',
  save: 'Сохранить',
  deleteProject2: 'Удалить проект',
  onceYouDeleteAProjectThereIsNoGoingBackPleaseBeCer: 'Как только вы удалите проект, вернуть его будет невозможно. Пожалуйста, будьте осторожны.',
  name2: 'Имя *',
  title: 'Заголовок *',
  description: 'Описание',
  required: 'Необходимый',
  key: '{expr}',
  surveyVariables: 'Опрос переменных',
  addVariable: 'Добавить переменную',
  columns: 'Столбцы',
  buildVersion: 'Версия сборки',
  messageOptional: 'Сообщение (дополнительно)',
  debug: 'Отладка',
  dryRun: 'Пробный запуск',
  diff: 'Разница',
  advanced: 'Расширенный',
  hide: 'Спрятать',
  pleaseAllowOverridingCliArgumentInTaskTemplateSett: 'Пожалуйста, разрешите переопределение аргумента CLI в настройках шаблона задачи',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe: 'Аргументы CLI (массив JSON). Например: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  started: 'Начал',
  author: 'Автор',
  duration: 'Продолжительность',
  stop: 'Стоп',
  deleteTeamMember: 'Удалить члена команды',
  team2: 'Команда',
  newTeamMember: 'Новый член команды',
  user: 'Пользователь',
  administrator: 'Администратор',
  definesStartVersionOfYourArtifactEachRunIncrements: 'Определяет начальную версию вашего артефакта. Каждый запуск увеличивает версию артефакта.',
  forMoreInformationAboutBuildingSeeThe: 'Дополнительную информацию о построении смотрите',
  taskTemplateReference: 'Справочник по шаблону задачи',
  definesWhatArtifactShouldBeDeployedWhenTheTaskRun: 'Определяет, какой артефакт должен быть развернут при запуске задачи.',
  forMoreInformationAboutDeployingSeeThe: 'Дополнительные сведения о развертывании смотрите',
  taskTemplateReference2: 'Справочник по шаблону задачи',
  definesAutorunSchedule: 'Определяет расписание автозапуска.',
  forMoreInformationAboutCronSeeThe: 'Дополнительные сведения о Крон см.',
  cronExpressionFormatReference: 'Документация по формату выражений Крон',
  startVersion: 'Начальная версия',
  example000: 'Например: 0.0.0',
  buildTemplate: 'Шаблон сборки',
  autorun: 'Автозапуск',
  playbookFilename: 'Имя файла плейбука *',
  exampleSiteyml: 'Например: site.yml',
  inventory2: 'Инвентарь *',
  репозиторий: 'Репозиторий *',
  environment3: 'Окружение *',
  vaultPassword: 'Vault пароль',
  vaultPassword2: 'Vault пароль',
  view: 'Вид',
  cron: 'Крон',
  iWantToRunATaskByTheCronOnlyForForNewCommitsOfSome: 'Я хочу запускать задачу по Крону только для новых коммитов некоторых репозиториев',
  репозиторий2: 'Репозиторий',
  cronChecksNewCommitBeforeRun: 'Проверять через Крон новые коммиты до запуска',
  readThe: 'Читать',
  toLearnMoreAboutCron: 'чтобы узнать больше о Крон.',
  suppressSuccessAlerts: 'Скрыть оповещения об успехе',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe2: 'Аргументы CLI (массив JSON). Например: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  allowCliArgsInTask: 'Разрешить рагументы CLI в задаче',
  docs: 'Документация',
  editViews: 'Изменить вид',
  newTemplate: 'Новый шаблон',
  taskTemplates2: 'Шаблоны задач',
  all: 'Все',
  notLaunched: 'Не запущен',
  by: 'к {user_name} {formatDate}',
  editTemplate: 'Изменить шаблон',
  newTemplate2: 'Новый шаблон',
  deleteTemplate: 'Удалить шаблон',
  playbook: 'Плейбук',
  email: 'Почта',
  adminUser: 'Администратор',
  sendAlerts: 'Отправить оповещение',
  deleteUser: 'Удалить пользователя',
  newUser: 'Новый пользователь',
  re: 'Пере{getActionButtonTitle}',
  teamMember: '{expr} Team Member',
  taskId: 'Номер задачи',
  version: 'Версия',
  status: 'Статус',
  start: 'Начать',
  actions: 'Действия',
  alert: 'Оповещение',
  admin: 'Администратор',
  role: 'Роль',
  external: 'Внешний',
  time: 'Время',
  path: 'Путь',
  gitUrl: 'Git URL',
  sshKey: 'SSH ключ',
  lastTask: 'Последняя задача',
  task2: 'Задача',
  build: 'Сборка',
  deploy: 'Развертывать',
  run: 'Запуск',
  add: 'Добавить',
  password_required: 'Требуется пароль',
  name_required: 'Требуется имя',
  user_credentials_required: 'Требуются учетные данные пользователя',
  type_required: 'Требуется тип',
  path_required: 'Требуется путь до файла инвенторя',
  private_key_required: 'Требуется приватный ключ',
  project_name_required: 'Требуется имя проекта',
  репозиторий_required: 'Требуется репозиторий',
  branch_required: 'Требуется ветка',
  key_required: 'Требуется ключ',
  user_required: 'Требуется пользователь',
  build_version_required: 'Требуется номер сборки',
  title_required: 'Требуется заголовок',
  isRequired: 'требуется',
  mustBeInteger: 'Должно быть целым числом',
  mustBe0OrGreater: 'Должно быть 0 или больше',
  start_version_required: 'Требуется стартовая версия',
  playbook_filename_required: 'Требуется имя файла плейбука',
  inventory_required: 'Требуется инвентарь',
  environment_required: 'Требуется окружение',
  email_required: 'Требуется почта',
  build_template_required: 'Требуется шаблон сборки',
  Task: 'Задача',
  Build: 'Сборка',
  Deploy: 'Развертывать',
  Run: 'Запуск',

};
